import React, { useMemo } from "react";
import sliderTwoImgOne from "Assets/Images/home/Collectors-01.png";
import sliderTwoImgTwo from "Assets/Images/home/Collectors-02.jpg";
import sliderOneImgOne from "Assets/Images/home/Creators-01.png";
import sliderOneImgTwo from "Assets/Images/home/Creators-02.png";
import DesktopSection from "./Components/DesktopSection";
import MobileSection from "./Components/MobileSection";
import { ROUTES_URL } from "App/Routes/Constants";

const Carousel = () => {
  const carouselList = useMemo(
    () => [
      {
        id: 1,
        sliderImgOne: sliderOneImgOne,
        sliderImgTwo: sliderOneImgTwo,
        urlLink: ROUTES_URL?.CREATORS,
        title: "Creators",
      },
      {
        id: 2,
        sliderImgOne: sliderTwoImgOne,
        sliderImgTwo: sliderTwoImgTwo,
        urlLink: ROUTES_URL?.COLLECTORS,
        title: "Collectors",
      },
    ],
    []
  );
  return (
    <>
      <DesktopSection carouselList={carouselList} />
      <MobileSection carouselList={carouselList}/>
    </>
  );
};

export default Carousel;
