
import { motion, useScroll, useTransform } from "framer-motion";
import "./AboutUsSection.scss";
import homeGradientFourteen from "Assets/Images/home/home-14.webp";
import homeGradientThirteen from "Assets/Images/home/home-13.webp";
import useScreenSize from "App/Hooks/ScreenSize";
import { useMemo, useRef } from "react";
import laptop from "Assets/Images/home/laptop.png";
import { Link } from "react-router-dom";
import { ROUTES_URL } from 'App/Routes/Constants';

const AboutUsSection = () => {
  const screenSize = useScreenSize()?.width;
  const textRef = useRef(null);
  const { scrollYProgress } = useScroll({
    target: textRef,
    offset: ["start end", "end start"],
  });
  const textXScroll = useTransform(scrollYProgress, [0, 0.4, 1], [-50, 0, 0]);
  const leftBarWebYScroll = useTransform(
    scrollYProgress,
    [1, 0.5, 0],
    [40, 0, 0]
  );
  const rightBarWebYScroll = useTransform(
    scrollYProgress,
    [1, 0.5, 0],
    [-40, 0, 0]
  );
  const leftBarMobYScroll = useTransform(
    scrollYProgress,
    [1, 0.5, 0],
    [0, 0, 0]
  );
  const rightBarMobYScroll = useTransform(
    scrollYProgress,
    [1, 0.5, 0],
    [0, 0, 0]
  );

  const leftBarYScroll = useMemo(() => {
    return screenSize > 768 ? leftBarWebYScroll : leftBarMobYScroll;
  }, [screenSize, leftBarWebYScroll, leftBarMobYScroll]);

  const rightBarYScroll = useMemo(() => {
    return screenSize > 768 ? rightBarWebYScroll : rightBarMobYScroll;
  }, [screenSize, rightBarWebYScroll, rightBarMobYScroll]);

  const imgRef = useRef(null);
  const imageScrollProgress = useScroll({
    target: imgRef,
    offset: ["start end", "end start"],
  });
  const imageXScroll = useTransform(
    imageScrollProgress.scrollYProgress,
    [0, 0.4, 1],
    [50, 0, 0]
  );
  return (
    <div className="about-us pl-[8.75rem] pr-[7.375rem]">
      <motion.div
        ref={textRef}
        className="text-section flex"
        style={{ x: textXScroll }}
      >
        <motion.img
          className="absolute right-bar gradient-bar"
          src={homeGradientFourteen}
          alt=""
          style={{ y: leftBarYScroll}}
        />
        <motion.img
          className="absolute left-bar gradient-bar"
          src={homeGradientThirteen}
          alt=""
          style={{ y: rightBarYScroll}}
        />
        <h2 className="md:w-[50%] w-full">About Us</h2>
        <div className="details md:w-[50%] w-full flex flex-col md:gap-[2.5rem] gap-4">
          <p>
            Cur8 develops software and display solutions that meet the
            discerning needs of creators, collectors, galleries and businesses.
            <br />
            <br />
            Whether showcasing images in a gallery or museum setting, providing
            high performance display solutions for businesses, or developing
            innovative tools for creators and curators, our mission is to bring
            best-in-class creators and technology together to create engaging,
            memorable experiences.
          </p>
          <Link to={ROUTES_URL?.CONTACTS} className="btn w-full gradient-see-more-btn">SEE MORE</Link>
        </div>
      </motion.div>
      <div className="md:px-0 sm:px-[2rem] px-4">
        <motion.div
          className="img-wrapper"
          ref={imgRef}
          style={{ x: imageXScroll }}
        >
          <img loading="lazy" src={laptop} alt="" />
        </motion.div>
      </div>
    </div>
  );
};
export default AboutUsSection;
