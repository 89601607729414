import logo from "Assets/Images/cur8-logo.svg";
import x from "Assets/Images/x.svg";
import discord from "Assets/Images/discord.svg";
import linkedin from "Assets/Images/linkedin.svg";
import footerGradientone from "Assets/Images/footer-1.webp";
import footerGradientTwo from "Assets/Images/footer-2.webp";
import "./Footer.scss";
import { Link, NavLink, useLocation } from "react-router-dom";
import { tabs } from "App/Constants";
import { useEffect } from "react";

const Footer = () => {
  const {pathname} = useLocation();
  const socialMedia = [
    { id: 1, icon: x, link: "https://twitter.com/Cur8Labs" },
    { id: 2, icon: discord, link: "https://discord.com/invite/vHqfQ5vPFd" },
    {
      id: 3,
      icon: linkedin,
      link: "https://www.linkedin.com/company/82127709/admin/feed/posts/",
    },
  ];

  useEffect(()=>{
    window.scrollTo(0, 0);
  },[pathname])

  const footerLiks = [
    {
      id: 1,
      text: "Terms of Use",
      link: "https://cur8.gitbook.io/cur8-knowledge-base/legal/terms-of-use",
    },
    {
      id: 2,
      text: "Privacy Policy",
      link: "https://cur8.gitbook.io/cur8-knowledge-base/legal/privacy-policy",
    },
  ];
  return (
    <div className="footer relative z-[2] h-full pb-[3rem]">
      <img
        className="absolute left-0 top-[-18dvw] md:max-h-[50dvw] max-h-[35rem]"
        src={footerGradientone}
        alt=""
      />
      <img
        className="absolute right-0 top-[-18dvw] md:max-h-[50dvw] max-h-[35rem]"
        src={footerGradientTwo}
        alt=""
      />
      <div className="wrapper">
        <div className="footer-logo">
          <img loading="lazy" src={logo} alt="" />
        </div>
        <div className="flex gap-[1.5rem] justify-center items-center social-media">
          {socialMedia?.map((val) => (
            <a
              key={val.id}
              href={val?.link}
              className="cursor-pointer"
              rel="noreferrer"
              target="_blank"
            >
              <img loading="lazy" src={val.icon} alt="" />
            </a>
          ))}
        </div>
        <div className="flex gap-1 tabs">
          {tabs?.map((val) => (
            <Link
              key={val.id}
              to={val?.link}
              className="cursor-pointer uppercase"
            >
              {val?.text}
            </Link>
          ))}
        </div>
        <div className="flex flex-col gap-4">
          <p>
            Cur8 2024
            <br />
            All rights reserved
          </p>
          <div className="footer-link-wrapper flex items-center gap-4">
            {footerLiks?.map((list) => (
              <NavLink
                to={list?.link}
                key={list?.id}
                target="_blank"
                className="footer-link"
              >
                {list?.text}
              </NavLink>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Footer;
