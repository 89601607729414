import About from "App/Pages/About";
import Collectors from "App/Pages/Collectors";
import Contact from "App/Pages/Contact";
import Creators from "App/Pages/Creators";
import Home from "App/Pages/Home";
import { Navigate, Route, Routes } from "react-router-dom";

const MainRoutes = () => {
  return (
    <Routes>
      <Route path="/" exact element={<Navigate to="/corporate" replace />}/>
      <Route path="/corporate" element={<Home />} />
      <Route path="/corporate/collectors" element={<Collectors />} />
      <Route path="/corporate/creators" element={<Creators />} />
      <Route path="/corporate/contact" element={<Contact />} />
      <Route path="/corporate/about" element={<About />} />
      <Route path="*" element={<Navigate to="/corporate" replace />} />
    </Routes>
  );
};

export default MainRoutes;
