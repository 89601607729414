import { Link, useLocation } from "react-router-dom";
import logo from "Assets/Images/nav-logo.svg";
import CloseIcon from "App/Components/CloseIcon";
import { tabs } from "App/Constants";
import { ROUTES_URL } from 'App/Routes/Constants';
import { useEffect } from "react";

export const NavbarContent = ({ setMenuCollapse }) => {
  const { pathname } = useLocation();

  const onCloseHandler = () => {
    setMenuCollapse && setMenuCollapse(false);
  };
  useEffect(()=>{
    onCloseHandler()
  // eslint-disable-next-line react-hooks/exhaustive-deps
  },[pathname])
  return (
    <div
      className={`scroll-menu-div flex w-full items-center h-full justify-between`}
    >
      <div className="flex justify-between w-full items-center">
        <Link to={ROUTES_URL?.HOME} className="logo-wrapper">
          <img loading="lazy"
            className={`cursor-pointer`}
            alt="No Img"
            src={logo}
          />
        </Link>
        <CloseIcon className="md:hidden flex" onCloseHandler={onCloseHandler} />
      </div>

      <div className="navbar-tabs flex align-center gap-[2rem]">
        <div className="flex gap-[1.875rem] items-center tabs-wrapper">
          {tabs?.map((el, i) => (
            <Link
              key={el?.id}
              to={el?.link}
              className={`tab-link ${pathname === el?.link ? "active":"inactive"}`}
            >
              {el?.text}
            </Link>
          ))}
          <a
            href={`${process.env.REACT_APP_USER_URL}`}
            target="_blank"
            rel="noreferrer"
            className="mixed-gradient-btn"
          >
            Launch App
          </a>
        </div>
      </div>
    </div>
  );
};

export default NavbarContent;
