export const ROUTES_WORDS={
    BASE:"corporate"
}

export const ROUTES_URL={
    HOME:`/${ROUTES_WORDS.BASE}`,
    COLLECTORS:`/${ROUTES_WORDS.BASE}/collectors`,
    CONTACTS:`/${ROUTES_WORDS.BASE}/contact`,
    CREATORS:`/${ROUTES_WORDS.BASE}/creators`,
    ABOUT:`/${ROUTES_WORDS.BASE}/about`
}